import { useSelector } from 'react-redux';
import { stepFormSelector } from '../../new-store/step-forms/selectors';

export const userTypes = {
  admin: 'admin',
  agency: 'agency',
  owner: 'owner',
  notOwner: 'notOwner',
};

// Note: campaign creater or member of the campaign team is campaign owner
// campaign owner has write access only if the status is prep. or missing information
// admin has write access irrespective of status.
// other users only read access
export const useCheckForOwnership = () => {
  const currentUserProfile = useSelector(({ authentication }) => authentication.profile);
  const currentUserWwid = currentUserProfile.wwid;

  const campaignProfile = useSelector(({ campaignProfile }) => campaignProfile.campaignProfile);
  const { campaign: { data: campaignStepForm } = {} } = useSelector(stepFormSelector);

  // We need to check both the campaignProfile and campaignStepForm.
  // Reason: we can access the step forms on both the customer journey and content production.
  // On the customer journey, the campaign profile is loaded. On the content production the campaign is loaded in the step form state.
  // So the result is the same but we need to check both parts of the state.
  const campaignTeamMembers = campaignProfile?.teamDetails ?? campaignStepForm?.team ?? [];
  
  const userPartOfCampaignTeam = campaignTeamMembers.find(
    (member) => member.wwid === currentUserWwid,
  );

  if (currentUserProfile.role?.toLowerCase() === 'admin' || currentUserProfile.role?.toLowerCase() === 'superadmin') {
    return { user: userTypes.admin, disableField: false };
  } else if (currentUserProfile.role?.toLowerCase() === 'agency') {
    return { user: userTypes.agency, disableField: false };
  } else if (userPartOfCampaignTeam) {
    return { user: userTypes.owner, disableField: false };
  } else {
    return { user: userTypes.notOwner, disableField: true };
  }
};
